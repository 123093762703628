import { dataService } from '@app/modules/data';
import { DataObject, ObjectOptions } from './base';

import { Session } from './session';
import { QrCode } from './qrcode';
import { Ticket } from './ticket';

import { AppConstants } from '@app/app.constants';

export interface _AskWaiter {
    status: string;
    session: number;
    qrcode: number;
    reason: string;
    amount: number;
    ticket: number;
};

interface _AskWaiterData extends _AskWaiter {
    objid?: number;
    _uuid?: string;
    created?: Date;
    updated?: Date;
};

abstract class AskWaiterData extends DataObject {
    protected _askwaiter: _AskWaiterData = {
        status: null,
        session: null,
        qrcode: null,
        reason: null,
        amount: null,
        ticket: null
    };

    constructor(table: string, objid: string, data: dataService, objoptions: ObjectOptions){
        super(table, objid, data, objoptions);
        this._askwaiter.created = new Date();
    }

    /****************************/
    /* CLASS MEMBERS            */
    /****************************/

    get created(){
        return this._askwaiter.created;
    }

    get updated(): Date {
        return this._askwaiter.updated || this._askwaiter.created;
    }

    get status(): string {
        return this._askwaiter.status;
    }

    set status(value: string){
        if (this.patchValue(this._askwaiter, 'status', value)){
            this.ToUpdate = true;
        }
    }

    get session(): Session {
        return this._children['session'] || null;
    }

    set session(value: Session){
        if (this.SetChild('session', value, 'session')){
            this.ToUpdate = true;
        }
    }

    get qrcode(): QrCode {
        return this._children['qrcode'] || null;
    }

    set qrcode(value: QrCode) {
        if (this.SetChild('qrcode', value, 'qrcode')){
            this.ToUpdate = true;
        }
    }

    get ticket(): Ticket {
        return this._children['ticket'] || null;
    }

    set ticket(value: Ticket) {
        if (this.SetChild('ticket', value, 'ticket')){
            this.ToUpdate = true;
        }
    }

    get reason(): string {
        return this._askwaiter.reason;
    }

    set reason(value: string) {
        if (this.patchValue(this._askwaiter, 'reason', value)){
            this.ToUpdate = true;
        }
    }

    get amount(): number {
        return this._askwaiter.amount;
    }

    set amount(value: number) {
        if (this.patchValue(this._askwaiter, 'amount', value)){
            this.ToUpdate = true;
        }
    }

    /****************************/
    /* COMMIT OPERATION         */
    /****************************/

    protected get Change() {
        return {
            status: this._askwaiter.status,
            session: this._askwaiter.session,
            qrcode: this._askwaiter.qrcode,
            reason: this._askwaiter.reason,
            amount: this._askwaiter.amount,
            ticket: this._askwaiter.ticket,
        };
    }

    protected get Depend() {
        return {
            session: { item: this.session, relation_info: { to: null, by: 'session' } },      // no relation to this in this[by -> 'session']
            qrcode: { item: this.qrcode, relation_info: { to: 'requests', by: 'qrcode' } },   // this[by -> 'qrcode'][to -> 'requests'] => this
            ticket: { item: this.ticket, relation_info: { to: null, by: 'ticket' } }          // no relation to this in this[by -> 'ticket']
        }
    }

    protected get Children() {
        return [ /* empty */ ];
    }

    /****************************/
    /* DATA OBJECT              */
    /****************************/
    
    private _patchData(_askwaiter: _AskWaiter){
        let _toUpdate = false;

        _toUpdate = this.patchValue(this._askwaiter, 'status', _askwaiter['status']) || _toUpdate;
        _toUpdate = this.patchValue(this._askwaiter, 'session', _askwaiter['session']) || _toUpdate;
        _toUpdate = this.patchValue(this._askwaiter, 'qrcode', _askwaiter['qrcode']) || _toUpdate;
        _toUpdate = this.patchValue(this._askwaiter, 'reason', _askwaiter['reason']) || _toUpdate;
        _toUpdate = this.patchValue(this._askwaiter, 'amount', _askwaiter['amount']) || _toUpdate;
        _toUpdate = this.patchValue(this._askwaiter, 'ticket', _askwaiter['ticket']) || _toUpdate;

        return _toUpdate;
    }

    set Data(_askwaiter: _AskWaiter){
        this.patchValue(this._askwaiter, 'created', _askwaiter['created']);
        this.patchValue(this._askwaiter, 'updated', _askwaiter['updated']);
        
        if (this._patchData(_askwaiter)){
            this.ToUpdate = true;
        }
    }

    get Info(){
        return this._askwaiter;
    }

    set Info(value){
        this.DoPatchValues(value);
    }

    private DoPatchValues(_askwaiter: _AskWaiter){
        this._patchData(_askwaiter);

        if (_askwaiter['session']){   // update children: 'session'
            let _objid = _askwaiter['session'].toString();
            this.SetChild('session', new Session(_objid, this.data), 'session')
        }
        else {
            this.SetChild('session', null, 'session')
        }
        
        if (_askwaiter['qrcode']){    // update children: 'qrcode'
            let _objid = _askwaiter['qrcode'].toString();
            this.SetChild('qrcode', new QrCode(_objid, this.data, this._objoptions), 'qrcode')
        }
        else {
            this.SetChild('qrcode', null, 'qrcode')
        }

        if (_askwaiter['ticket']){    // update children: 'ticket'
            let _objid = _askwaiter['ticket'].toString();
            this.SetChild('ticket', new Ticket(_objid, this.data, this._objoptions), 'ticket')
        }
        else {
            this.SetChild('ticket', null, 'ticket')
        }
    }

    private _ddbb(info): _AskWaiterData {
        let _askwaiter: _AskWaiterData = {
            objid: info['objid'] ? parseInt(info['objid']) : null,
            created: new Date(Date.parse(this.mysqlToDateStr(info['created']))),
            updated: new Date(Date.parse(this.mysqlToDateStr(info['updated']))),            
            status: info['status'],
            session: info['session'] ? parseInt(info['session']): null,
            qrcode: info['qrcode'] ? parseInt(info['qrcode']) : null,
            reason: info['reason'],
            amount: info['amount'] ? parseFloat(info['amount']) : 0,
            ticket: info['ticket'] ? parseInt(info['ticket']): null
        };

        return _askwaiter;
    }

    protected _OnUpdate(info){
        let _askwaiter = this._ddbb(info);
        this.patchValue(this._askwaiter, 'objid', _askwaiter['objid']);
        this.patchValue(this._askwaiter, 'created', _askwaiter['created']);
        this.patchValue(this._askwaiter, 'updated', _askwaiter['updated']);
        this.DoPatchValues(_askwaiter);   
    }
}

export class AskWaiter extends AskWaiterData {
    constructor(objid: string, data: dataService, objoptions: ObjectOptions = null){
        super('ASKWAITER', objid, data, objoptions);
    }

    Copy(store: Array<DataObject> = []): AskWaiter {
        return this._Copy(store) as AskWaiter;
    }

    /****************************/
    /* CLASS MEMBERS            */
    /****************************/

    get status(): string {
        return super.status;
    }

    set status(value: string){
        if (this.status == 'DE'){
            return;     // cannot modify deleted items
        }

        super.status = value;
        if (this.qrcode){
            if ((this.status == 'DE') && (this.ToInsert) && (!this.CopyOf || this.CopyOf.ToInsert)){
                this.qrcode.DelRequest(this);
            }
            else {
                this.qrcode.DoRefresh('ASKWAITER');
            }    
        }
    }  
    
    get ticket(): Ticket {
        return super.ticket;
    }

    set ticket(value: Ticket){
        super.ticket = value;
        super.qrcode = value.qrcode;
    }

    /****************************/
    /* CUSTOM METHODS           */
    /****************************/

    InSession(session){
        if ((this.IsValid) && (this.IsRecent)){
            if (this.session == session) {
                return true;    // same session
            }

            if ((this.qrcode == session.qrcode) && (this.reason == 'REFUND')){
                return true;    // Refund on this table
            }
        }

        return false;
    }

    get IsValid() {
        if ((!this.status) || (this.status == 'DE')){
            return false;
        }
        
        if (this.ticket){
            if (!this.ticket.IsValid){
                return false;   // invalid ticket request
            }

            if (this.ticket.IsPaid && (['PAYCASH', 'PAYCARD', 'PAYMIXED'].includes(this.reason))){
                return false;   // request to pay a paid ticket
            }
        } 

        return true;
    }

    get IsRecent(){
        return this.created.getTime() > ((new Date()).getTime() - AppConstants.recentMs);
    }

    get Amount(){
        let _amount = {
            Str: "0,00",
            Int: "0",
            Dec: "00",
            Neg: false
        };

        if (this._askwaiter.amount !== null){
            let _parts = Math.abs(this._askwaiter.amount).toString().split('.');

            let _int = _parts[0];
            let _dec = (_parts.length > 1) ? (_parts[1] + '0').slice(0, 2) : "00";
            let _str = _int + ',' + _dec;
            let _neg = (this._askwaiter.amount < 0); 

            _amount = {
                Int: _int,
                Dec: _dec,
                Str: _str,
                Neg: _neg
            }    
        }

        return _amount;
    }
}
