import { Component, Directive } from '@angular/core';
import { OnInit, OnDestroy } from '@angular/core';
import { ChangeDetectionStrategy, ChangeDetectorRef } from '@angular/core';
import { HostListener, ElementRef } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { languageService } from '@app/modules/common/language';
import { kioskService } from '@app/modules/common/kiosk';

/**********************************/
/* DIRECTIVE TO CONTROL KEYBOARD  */
/**********************************/

@Directive({
    selector: '.ngkiosk'
})
export class UiKioskInput {
    constructor(private el: ElementRef, private kiosk: kioskService){
        // nothing to do
    }

    @HostListener('focus', ['$event.target']) onFocus(input: HTMLInputElement) {
        if (!input.disabled){
            this.kiosk.show(input, this.el.nativeElement.getAttribute('data-kioskboard-type'));
        }
    }
    
    @HostListener('blur') onBlur() {
        this.kiosk.hide();
    }
}

/**********************************/
/* KEYBOARD COMPONENT             */
/**********************************/

@Component({
    selector: 'ui-kiosk-board',
    templateUrl:'./ui-kiosk.html',
    styleUrls: [ 
        './ui-kiosk.scss'
    ],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class UiKioskBoard implements OnInit, OnDestroy {
    private _type = null;
    get type(){
        return this._type;
    }

    set type(value){
        this._type = value;
        this.change.markForCheck();
    }

    private _capslock = false;
    get capslock(){
        return this._capslock;
    }

    set capslock(value){
        if (this._capslock != value){
            this._capslock = value;
            this.change.markForCheck();
        }
    }

    private _specchar = false;
    get specchar(){
        return this._specchar;
    }

    set specchar(value){
        if (this._specchar != value){
            this._specchar = value;
            this.change.markForCheck();
        }
    }

    get switch(){
        if (this.specchar){
            return "abc";
        }
        else {
            return "*+=";
        }
    }

    private _spcLayout = [
        { "0": "!", "1": "'", "2": "^", "3": "#", "4": "+", "5": "$", "6": "%", "7": "&", "8": "/", "9": "=" },
        { "0": "{", "1": "}", "2": "(", "3": ")", "4": "[", "5": "]", "6": "*", "7": "¿", "8": "?", "9": "|" },
        { "0": "@", "1": "-", "2": "_", "3": "€", "4": "~", "5": "<", "6": ">", "7": ",", "8": ";", "9": "ç" },
        { "0": "`", "1": "´", "2": ".", "3": ":", "4": "$", "5": "BR" }
    ]

    private _numLayout = [
        { "0": "7", "1": "8", "2": "9" },
        { "0": "4", "1": "5", "2": "6" },
        { "0": "1", "1": "2", "2": "3" },
        { "0": "0", "1": "RT", "2": "BK" }
    ]

    private _keyLayout = [];    // loaded based on language
    get keylayout(){
        if ((this.type == 'num') || (this.type == 'price')){
            return this._numLayout;
        }

        return (this.specchar) ? this._spcLayout : this._keyLayout;
    }

    set keylayout(value){
        if (value){     // add the break line key 
            let _lastrow = value[value.length - 1];
            let _lastkey = Math.max(...Object.keys(_lastrow).map(Number));
            _lastrow[_lastkey + 1] = 'BR';
        }

        this._keyLayout = value;
        this._keyLayout
        this.change.markForCheck();
    }

    get keywidth(){
        let _maxkeys = 0;
        for (let _row of this._keyLayout){
            let _rowkeys = Object.keys(_row).length;
            if (_rowkeys > _maxkeys){
                _maxkeys = _rowkeys; 
            }
        }

        return "calc(" + (100/_maxkeys) + "% - 6px)";
    }

    get inputValue(){
        if (this.kiosk.value){
            if (this._type == 'price'){
                return (Number(this.kiosk.value)/100).toFixed(2);
            }
            else {
                return this.kiosk.value.replace(/\n/g, '\u21B5');
            }
        }
        return '';
    }

    // do not loose focus on input when keyboard is pressed
    private _allowpropagation = false;
    OnMouseDown(event){
        if (this._allowpropagation == false){
            event.preventDefault();
            event.stopPropagation();    
        }

        this._allowpropagation = false;
    }

    // loose focus on input when return key is pressed (overrides OnMouseDown)
    OnPropagate(){
        this._allowpropagation = true;
    }

    /************************/
    /* MAIN STARTS HERE     */
    /************************/

    private _layouts = {
        'en': '/assets/scripts/kioskboard/kioskboard-keys-english.json',
        'es': '/assets/scripts/kioskboard/kioskboard-keys-spanish.json',
        'fr': '/assets/scripts/kioskboard/kioskboard-keys-french.json',
        'de': '/assets/scripts/kioskboard/kioskboard-keys-german.json',
        'hu': '/assets/scripts/kioskboard/kioskboard-keys-hungarian.json',
        'rs': '/assets/scripts/kioskboard/kioskboard-keys-russian.json',
        'tk': '/assets/scripts/kioskboard/kioskboard-keys-turkish.json'
    };

    constructor(private lang: languageService, private change: ChangeDetectorRef, private http: HttpClient, private kiosk: kioskService){
        // nothing to do
    }

    private _show_subscription = null;
    private _hide_subscription = null;
    private _lang_subscription = null;

    private _updateLayout(lg){
        if (!this._layouts[this.lang.GetLanguage(false)]){
            console.error("[KIOSK] Layout not available (" + this.lang.GetLanguage(false) + ") defaults to 'es'");
        }

        let _layout = this._layouts[this.lang.GetLanguage(false)] || this._layouts['es'];
        let _request = this.http.get(_layout);
        if (_request){
            let _subscription = _request.subscribe(
            (data) => {
                _subscription.unsubscribe();
                this.keylayout = data as any;
            }, (error) => {
                if (error.status != 0){
                    console.error("[HTTP] request error '" + error.status + "' in request to '" + _layout + "'")
                }
                else {
                    console.error("[HTTP] status = 0");
                }
            });
        }
    }

    ngOnInit() {
        this._updateLayout(this.lang.GetLanguage(false));
        this._lang_subscription = this.lang.OnSetLanguage.subscribe(
        data => {
            this._updateLayout(this.lang.GetLanguage(false))
        });

        this._show_subscription = this.kiosk.OnKeyBoardShow.subscribe(
        type => {
            this.show(type);
        });

        this._hide_subscription = this.kiosk.OnKeyBoardHide.subscribe(
        data => {
            this.hide();
        });    
    }

    ngOnDestroy() {
        if (this._lang_subscription){
            this._lang_subscription.unsubscribe();
            this._lang_subscription = null;
        }

        if (this._show_subscription){
            this._show_subscription.unsubscribe();
            this._show_subscription = null;
        }

        if (this._hide_subscription){
            this._hide_subscription.unsubscribe();
            this._hide_subscription = null;
        }
    }

    show(type) {
        this.type = type;
    }

    hide() {
        this.type = null;
    }

    clear(){
        this.kiosk.ClearInput()
    }

    public toL = false;
    public toR = false;

    lcompact(){
        this.toL = true;
        this.toR = false;
        this.change.markForCheck();
    }

    rcompact(){
        this.toL = false;
        this.toR = true;
        this.change.markForCheck();
    }

    expand(){
        this.toL = false;
        this.toR = false;
        this.change.markForCheck();
    }

    onKeyPress(key: string) {
        this.kiosk.keyPress(key);
        this.change.markForCheck();
    }

    capsLock() {
        this.capslock = !this.capslock;
        this.change.markForCheck();
    }    
  
    specChar() {
        this.specchar = !this.specchar;
        this.change.markForCheck();
    }

    private _keyval(key){
        if (key.length > 1){   
            return key;     // special keys
        }

        return (this.capslock) ? key.toUpperCase() : key.toLowerCase();
    }

    rowkeys(row: any) {
        let _keys = [];
        for (let _key of Object.keys(row)){
            _keys.push(this._keyval(row[_key]));
        }
        return _keys;
    }
}