import { AppConstants } from '@app/app.constants';
import { dataService } from '@app/modules/data';
import { GenericUtils } from '@app/app.commonutils';
import { DataObject, ObjectOptions } from './base';

import { User } from './user';
import { Address } from './address';
import { StripeAccount } from './stripe';
import { PayAccount } from './payaccount';
import { PlaceOption } from './placeoption';
import { PlaceLink } from './placelink';
import { PlaceService } from './svcplace';
import { PlaceArea } from './placearea';
import { QrCode } from './qrcode';
import { Product } from './product';
import { Offer } from './offer';
import { Extra } from './extra';
import { Waiter } from './waiter';
import { Ticket } from './ticket';
import { Discount } from './discount';
import { CashChange } from './cashchange';
import { Audit } from './audit';
import { Payment} from './payment';
import { Business } from './business';
import { Family } from './family';

import { Subject } from 'rxjs';

/************************************/
/* SEARCH TOOL                      */
/************************************/

export class searchTool {
    
    constructor(){
        // nothing to do
    }
    
    /************************************/
    /* SUPPORT METHODS                  */
    /************************************/

    private _noacute(c) {
        switch (c) {
            case 'á':
            case 'à':
            case 'ä':
                return 'a';
            case 'é':
            case 'è':
            case 'ë':
                return 'e';
            case 'í':
            case 'ì':
            case 'ï':
                return 'i';
            case 'ó':
            case 'ò':
            case 'ö':
                return 'o';
            case 'ú':
            case 'ù':
            case 'ü':
                return 'u';
        }
        
        return c;
    }
    
    private _noacute_word(chararray){
        let _chararray = [];
        for(let i=0; i < chararray.length; i++){
            _chararray.push(this._noacute(chararray[i]));
        }
        return _chararray;
    }
            
    private _levenshtein(a: string, b: string): number {
        const an = a ? a.length : 0;
        const bn = b ? b.length : 0;
        if (an === 0) return bn;
        if (bn === 0) return an;

        const matrix: number[][] = [];

        for (let i = 0; i <= bn; i++) {
            matrix[i] = [i];
        }

        for (let j = 0; j <= an; j++) {
            matrix[0][j] = j;
        }

        for (let i = 1; i <= bn; i++) {
            for (let j = 1; j <= an; j++) {
                if (b.charAt(i - 1) === a.charAt(j - 1)) {
                    matrix[i][j] = matrix[i - 1][j - 1];
                } else {
                    matrix[i][j] = Math.min(
                        matrix[i - 1][j - 1] + 1,
                        Math.min(matrix[i][j - 1] + 1, matrix[i - 1][j] + 1)
                    );
                }
            }
        }

        return matrix[bn][an];
    }

    private _is_similar_word(srchstr: string, compstr: string): number {
        let _w1str = this._noacute_word([...compstr]).join('');
        let _w2str = this._noacute_word([...srchstr]).join('');
        
        let _dist = this._levenshtein(_w1str, _w2str);
        return 100 - (_dist / Math.max(_w1str.length, _w2str.length) * 100);
    }
    
    private _find_substring(srchstr: string, compstr: string): number {
        let _srchstr = srchstr.toLowerCase();
        let _compstr = compstr.toLowerCase();

        let _nomrsrch = _srchstr.split('').map(this._noacute).join('');
        let _normcomp = _compstr.split('').map(this._noacute).join('');

        let _maxthreshold = 0;
        for (let i = 0; i <= _normcomp.length - _nomrsrch.length; i++) {
            let _sbcompare = _normcomp.substring(i, i + _nomrsrch.length);
            let _threshold = this._is_similar_word(_nomrsrch, _sbcompare);
            if (_threshold > _maxthreshold) {
                _maxthreshold = _threshold;
            }
        }

        return _maxthreshold;
    }

    private _product_to_string(product){
        let _product_str = "";
        
        // provide results with parent names
        let _parent = product.parent;
        while (_parent){
            _product_str += _parent.Info['name'] + "|";
            _parent = _parent.parent;
        }

        // provide results with name or description
        _product_str += product.Info['name'];
        if (product.Info['description']){
            _product_str += "/" + product.Info['description'];
        }

        // provide results with product options
        if ('categories' in product){
            for(let _category of product.categories){
                for (let _option of _category.options){
                    _product_str += ";" + _option.Info['name'];
                }
            }    
        }
        return _product_str;
    }

    private _search_product(search: string, product: any): number {
        return this._find_substring(search, this._product_to_string(product));
    }
    
    /************************************/
    /* PUBLIC METHODS                   */
    /************************************/
    
    doSearch(text, products, threshold = 0){
        let _results = [];
        
        // add all candidates to the results array
        let _heuristic = 0;
        for(let _product of products){
            if (_product.isgroup){
                continue;
            }

            _heuristic = this._search_product(text, _product);
            if (_heuristic > threshold){
                _results.push({
                    heuristic: _heuristic,
                    candidate: _product
                });        
            }

            for(let _direct of _product.directs){
                _heuristic = this._search_product(text, _direct);
                if (_heuristic > threshold){
                    _results.push({
                        heuristic: _heuristic,
                        candidate: _direct
                    });        
                }    
            }    
        }
        
        // order the results array 
        _results.sort((a, b) => { 
            return (b['heuristic'] - a['heuristic']); 
        }); 
        
        // return the match products
        let _products = [];
        for(let _result of _results){
            _products.push(_result['candidate']);
        }
        return _products;
    } 
}

/************************************/
/* PLACE OBJECT                     */
/************************************/

export interface _Place {
    status: string;
    user: number;
    name: string;
    description: string;
    photo: {
        url: string;
        b64: any;
    },
    logo: {
        url: string;
        b64: any;
    }
    expires: Date;
    address: number;    
    business: string;
    company: boolean;
    activity: string;
    taxid: string;
    fiscal: number;
    phone: string;
};

interface _PlaceData extends _Place {
    objid?: number;
    _uuid?: string;
    created?: Date;
};

abstract class PlaceData extends DataObject {
    protected _place: _PlaceData = {
        status: null,
        user: null,
        name: null,
        description: null,
        photo: {
            url: null,
            b64: null
        },
        logo: {
            url: null,
            b64: null
        },
        expires: null,
        address: null,
        business: null,
        company: null,
        activity: null,
        taxid: null,
        fiscal: null,
        phone: null
    };

    constructor(table: string, objid: string, data: dataService, objoptions: ObjectOptions){
        super(table, objid, data, objoptions);
        this._place.created = new Date();
    }

    /****************************/
    /* CLASS MEMBERS            */
    /****************************/

    get created(){
        return this._place.created;
    }

    get status(): string {
        return this._place.status;
    }

    set status(value: string){
        if (this.patchValue(this._place, 'status', value)){
            this.ToUpdate = true;
        }
    }

    get user(): User {
        return this._children['user'] || null;
    }

    set user(value: User){
        if (this.SetChild('user', value, 'user')){
            this.ToUpdate = true;
        }
    }

    get name(): string {
        return this._place.name;
    }

    set name(value: string){
        if (this.patchValue(this._place, 'name', value)){
            this.ToUpdate = true;
        }
    }

    get description(): string {
        return this._place.description;
    }

    set description(value: string){
        if (this.patchValue(this._place, 'description', value)){
            this.ToUpdate = true;
        }
    }

    get photo(): string{
        return this._place.photo.url;
    }

    set photo(value: string){
        if (this.patchValue(this._place.photo, 'url', value)){
            this.ToUpdate = true;
        }
    }

    get photo64(): any {
        return this._place.photo.b64;        
    }

    set photo64(value: any){
        if (this.patchValue(this._place.photo, 'b64', value)){
            this.ToUpdate = true;
        }
    }

    get logo(): string{
        return this._place.logo.url;
    }

    set logo(value: string){
        if (this.patchValue(this._place.logo, 'url', value)){
            this.ToUpdate = true;
        }
    }

    get logo64(): any {
        return this._place.logo.b64;        
    }

    set logo64(value: any){
        if (this.patchValue(this._place.logo, 'b64', value)){
            this.ToUpdate = true;
        }
    }

    get expires(): Date {
        return this._place.expires;
    }

    set expires(value: Date){
        if (this.patchValue(this._place, 'expires', value)){
            this.ToUpdate = true;
        }
    }

    get business(): string {
        return this._place.business;
    }

    set business(value: string){
        if (this.patchValue(this._place, 'business', value)){
            this.ToUpdate = true;
        }
    }

    get company(): boolean {
        return (this._place.company === null) || !!this._place.company;
    }

    set company(value: boolean) {
        if (this.patchValue(this._place, 'company', value)){
            this.ToUpdate = true;
        }        
    }

    get activity(): string {
        return this._place.activity;
    }

    set activity(value: string) {
        if (this.patchValue(this._place, 'activity', value)){
            this.ToUpdate = true;
        }        
    }

    get taxid(): string {
        return this._place.taxid;
    }

    set taxid(value: string) {
        if (this.patchValue(this._place, 'taxid', value)){
            this.ToUpdate = true;
        }
    }

    get phone(): string {
        return this._place.phone;
    }

    set phone(value: string){
        if (this.patchValue(this._place, 'phone', value)){
            this.ToUpdate= true;
        }
    }

    get address() : Address {
        return this._children['address'] || null;
    }

    set address(value: Address){
        if (this.SetChild('address', value, 'address')){
            this.ToUpdate = true;
        }
    }

    get fiscal() : Address {
        return this._children['fiscal'] || null;
    }

    set fiscal(value: Address){
        if (this.SetChild('fiscal', value, 'fiscal')){
            this.ToUpdate = true;
        }
    }

    get stripe(): StripeAccount {
        return this._children['stripe'] || null;
    }

    set stripe(value: StripeAccount){
        if (this.SetChild('stripe', value, 'stripe')){
            this.ToUpdate = true;
        }
    }

    /* quick access to options */

    protected _optionsmap = null;

    protected get OptionsMap(){
        if (this._optionsmap == null){
            let _options = new Map <string, PlaceOption> ();

            // latest updated objid is the valid one (in case of duplicates)
            let _sortedopts = (this.options).sort((a, b) => {
                return a.updated.getTime() - b.updated.getTime(); 
            })

            for (let _option of _sortedopts) {
                _options.set(_option.opkey, _option);
            }

            this._optionsmap = _options;
        }

        return this._optionsmap;
    }

    /****************************/
    /* CHILDREN MANAGEMENT      */
    /****************************/

    AddOption(child: PlaceOption){
        this.AddChild('options', child, 'place');
    }

    AddLink(child: PlaceLink){
        this.AddChild('links', child, 'place');
    }

    AddTable(child: QrCode){
        this.AddChild('tables', child, 'place');
    }

    DelTable(child: QrCode){
        this.DelChild('tables', child, 'place');
    }

    AddProduct(child: Product){
        this.AddChild('products', child, 'place');
    }
   
    DelProduct(child: Product){
        this.DelChild('products', child, 'place');
    }

    AddOffer(child: Offer){
        this.AddChild('offers', child, 'place');
    }

    DelOffer(child: Offer){
        this.DelChild('offers', child, 'place');
    }

    AddExtra(child: Extra){
        this.AddChild('extras', child, 'place');
    }

    DelExtra(child: Extra){
        this.DelChild('extras', child, 'place');
    }

    AddWaiter(child: Waiter){
        this.AddChild('waiters', child, 'place');
    }

    DelWaiter(child: Waiter){
        this.DelChild('waiters', child, 'place');
    }

    AddTicket(child: Ticket){
        this.AddChild('tickets', child, 'place');
    }

    DelTicket(child: Ticket){
        this.DelChild('tickets', child, 'place');
    }

    AddPayAccount(child: PayAccount){
        this.AddChild('accounts', child, 'place');
    }

    DelPayAccount(child: PayAccount){
        this.DelChild('accounts', child, 'place');
    }

    AddDiscount(child: Discount){
        this.AddChild('discount', child, 'place');
    }

    DelDiscount(child: Discount){
        this.DelChild('discount', child, 'place');
    }

    AddService(child: PlaceService){
        this.AddChild('service', child, 'place');
    }

    DelService(child: PlaceService){
        this.DelChild('service', child, 'place');
    }

    AddAudit(child: Audit){
        this.AddChild('audits', child, 'place');
    }

    AddCashChange(child: CashChange){
        this.AddChild('cashchange', child, 'place');
    }

    /****************************/
    /* CHILD ACCESS             */
    /****************************/

    get options() : Array <PlaceOption> {
        return this._chldlist['options'] || [];
    }

    get links() : Array <PlaceLink> {
        return this._chldlist['links'] || [];
    }

    get tables() : Array <QrCode> {
        return this._chldlist['tables'] || [];
    }

    get products() : Array <Product> {
        return this._chldlist['products'] || [];
    }

    get offers() : Array <Offer> {
        return this._chldlist['offers'] || [];
    }

    get extras() : Array <Extra> {
        return this._chldlist['extras'] || [];
    }

    get waiters() : Array <Waiter> {
        return this._chldlist['waiters'] || [];
    }

    get tickets() : Array <Ticket> {
        return this._chldlist['tickets'] || [];
    }

    get cashchanges(): Array<CashChange> {
        return this._chldlist['cashchange'] || [];
    }

    get audits() : Array <Audit> {
        return this._chldlist['audits'] || [];
    }

    get payaccounts() : Array <PayAccount> {
        return this._chldlist['accounts'] || [];
    }

    get transaction() : Array <Payment> {
        return this._chldlist['transaction'] || [];
    }

    get discounts(): Array <Discount> {
        return this._chldlist['discount'] || [];
    }

    get services() : Array <PlaceService> {
        return this._chldlist['service'] || [];
    }

    get clients() : Array <Business> {
        let _clients = this._chldlist['clients'] || [];

        return _clients.sort((a, b) => {
            let _a_last = a.last || new Date(0);    // last is null if there are no invoices for this business
            let _b_last = b.last || new Date(0);    // last is null if there are no invoices for this business

            return _b_last.getTime() - _a_last.getTime();
        });
    }

    get areas() : Array <PlaceArea> {
        let _areas = new Set <PlaceArea> ();

        for(let _qrcode of this.tables){
            if (_qrcode.area){
                _areas.add(_qrcode.area);
            }
        }

        return Array.from(_areas);;
    }

    /****************************/
    /* COMMIT OPERATION         */
    /****************************/

    protected get Change() {
        return (async () => {
            let _photo64 = this.photo64;
            if (!_photo64){
                let _uploadUrl = AppConstants.baseURL + AppConstants.uploadPath;
                if (this.photo && !this.photo.startsWith(_uploadUrl)){
                    this.photo64 = await this.uploadTobase64(this.photo);
                }
            }

            let _logo64 = this.logo64;
            if (!_logo64){
                let _uploadUrl = AppConstants.baseURL + AppConstants.uploadPath;
                if (this.logo && !this.logo.startsWith(_uploadUrl)){
                    this.logo64 = await this.uploadTobase64(this.logo);
                }
            }

            return {
                user: this._place.user,
                status: this._place.status,
                name: this._place.name,
                description: this._place.description,
                photo: {
                    url: this.uploadToMysql(this.photo),
                    b64: this.photo64
                },
                logo: {
                    url: this.uploadToMysql(this.logo),
                    b64: this.logo64
                },
                expires: this.dateStrToMysql(this._place.expires),
                address: this._place.address,
                business: this._place.business,
                company: this._place.company? '1': '0',
                activity: this._place.activity,
                taxid: this._place.taxid,
                fiscal: this._place.fiscal,
                phone: this._place.phone,
            };
        })();   
    }

    protected get Depend() {
        return {
            user: { item: this.user, relation_info: { to: 'places', by: 'user' } },         // this[by -> 'user'][to -> 'places'] => this
            address: { item: this.address, relation_info: { to: null, by: 'address' } },    // no relation to this in this[by -> 'address']
            fiscal: { item: this.fiscal, relation_info: { to: null, by: 'fiscal' } }        // no relation to this in this[by -> 'fiscal']
        };
    }

    protected get Children(){
        let _children = [];

        if (this.address){
            _children.push(this.address);
        }     

        if (this.fiscal){
            _children.push(this.fiscal);
        }

        if (this.stripe){
            _children.push(this.stripe);
        }     

        for(let _item of this.services){
            _children.push(_item);
        }
        
        for(let _item of this.options){
            _children.push(_item);
        }

        for(let _item of this.links){
            _children.push(_item);
        }

        for(let _item of this.tables){
            _children.push(_item);
        }

        for(let _item of this.areas){
            _children.push(_item);
        }

        for(let _item of this.products){
            _children.push(_item);
        }

        for(let _item of this.offers){
            _children.push(_item);
        }

        for(let _item of this.extras){
            _children.push(_item);
        }

        for(let _item of this.waiters){
            _children.push(_item);
        }

        for(let _item of this.discounts){
            _children.push(_item);
        }

        for(let _item of this.tickets){
            _children.push(_item);
        }

        for(let _item of this.cashchanges){
            _children.push(_item);
        }

        for(let _item of this.audits){
            _children.push(_item);
        }

        for(let _item of this.payaccounts){
            _children.push(_item);
        }

        for(let _item of this.transaction){
            _children.push(_item);
        }

        return _children;
    }

    /****************************/
    /* DATA OBJECT              */
    /****************************/
    
    private _patchData(_place: _Place){
        let _toUpdate = false;

        _toUpdate = this.patchValue(this._place, 'status', _place['status']) || _toUpdate;
        _toUpdate = this.patchValue(this._place, 'user', _place['user']) || _toUpdate;
        _toUpdate = this.patchValue(this._place, 'name', _place['name']) || _toUpdate;
        _toUpdate = this.patchValue(this._place, 'description', _place['description']) || _toUpdate;
        _toUpdate = this.patchValue(this._place, 'photo', _place['photo']) || _toUpdate;
        _toUpdate = this.patchValue(this._place, 'logo', _place['logo']) || _toUpdate;
        _toUpdate = this.patchValue(this._place, 'expires', _place['expires']) || _toUpdate;
        _toUpdate = this.patchValue(this._place, 'address', _place['address']) || _toUpdate;
        _toUpdate = this.patchValue(this._place, 'service', _place['service']) || _toUpdate;
        _toUpdate = this.patchValue(this._place, 'business', _place['business']) || _toUpdate;
        _toUpdate = this.patchValue(this._place, 'company', _place['company']) || _toUpdate;
        _toUpdate = this.patchValue(this._place, 'activity', _place['activity']) || _toUpdate;
        _toUpdate = this.patchValue(this._place, 'taxid', _place['taxid']) || _toUpdate;
        _toUpdate = this.patchValue(this._place, 'fiscal', _place['fiscal']) || _toUpdate;
        _toUpdate = this.patchValue(this._place, 'phone', _place['phone']) || _toUpdate;

        return _toUpdate;
    }    

    set Data(_place: _Place){
        this.patchValue(this._place, 'created', _place['created']);
        
        if (this._patchData(_place)){
            this.ToUpdate = true;
        }
   }

    get Info(){
        return this._place;
    }

    set Info(value){
        this.DoPatchValues(value);
    }

    private DoPatchValues(_place: _Place){
        this._patchData(_place);

        if (_place['user']) {   // update children: 'user'
            let _objid = _place['user'].toString();
            this.SetChild('user', new User(_objid, this.data, this._objoptions), 'user');            
        }
        else {
            this.SetChild('user', null, 'user');            
        }

        if (_place['address']){   // update children: 'address'
            let _objid = _place['address'].toString();
            this.SetChild('address', new Address(_objid, this.data, this._objoptions), 'address');
        }
        else {
            this.SetChild('address', null, 'address');
        }

        if (_place['fiscal']){   // update children: 'fiscal'
            let _objid = _place['fiscal'].toString();
            this.SetChild('fiscal', new Address(_objid, this.data, this._objoptions), 'fiscal');
        }
        else {
            this.SetChild('fiscal', null, 'fiscal');
        }     
    }

    private _ddbb(info): _PlaceData {
        let _place: _PlaceData = {
            objid: info['objid'] ? parseInt(info['objid']) : null,
            created: new Date(Date.parse(this.mysqlToDateStr(info['created']))),
            status: info['status'],
            user: info['user'] ? parseInt(info['user']) : null,
            name: info['name'],
            description: info['description'],
            photo: {
                url: this.mysqlToUpload(info['photo']),
                b64: null
            },
            logo: {
                url: this.mysqlToUpload(info['logo']),
                b64: null
            },            
            expires: new Date(Date.parse(this.mysqlToDateStr(info['expires']))),
            address: info['address'] ? parseInt(info['address']) : null,
            business: info['business'],
            company: info['company'] && (info['company'] == '1'),
            activity: info['activity'],
            taxid: info['taxid'],
            fiscal: info['fiscal'] ? parseInt(info['fiscal']) : null,
            phone: info['phone']
        };

        return _place;
    }

    protected _OnUpdate(info){
        let _place = this._ddbb(info);

        this.patchValue(this._place, 'objid', _place['objid']);
        this.patchValue(this._place, 'created', _place['created']);
        this.DoPatchValues(_place);

        if ('products' in info){   // update children: 'products'
            this.SetChildren <Product> (info['products'], 'products', Product, 'place');
        }

        if ('options' in info){   // update children: 'options'
            this.SetChildren <PlaceOption> (info['options'], 'options', PlaceOption, 'place');
        }
        
        if ('links' in info) {    // update children: 'links'  
            this.SetChildren <PlaceLink> (info['links'], 'links', PlaceLink, 'place');
        }

        if ('tables' in info) {   // update children: 'tables'
            this.SetChildren <QrCode> (info['tables'], 'tables', QrCode, 'place');
        }

        if ('offers' in info) {   // update children: 'offers'
            this.SetChildren <Offer> (info['offers'], 'offers', Offer, 'place');
        }
        
        if ('extras' in info) {   // update children: 'extras'
            this.SetChildren <Extra> (info['extras'], 'extras', Extra, 'place');
        }

        if ('waiters' in info) {  // update children: 'waiters'
            this.SetChildren <Waiter> (info['waiters'], 'waiters', Waiter, 'place');
        }

        if ('discounts' in info) {  // update children: 'discounts'
            this.SetChildren <Discount> (info['discounts'], 'discount', Discount, 'place');
        }

        if ('tickets' in info) {  // update children: 'tickets'
            this.SetChildren <Ticket> (info['tickets'], 'tickets', Ticket, 'place');
        }

        if ('cashchange' in info) {  // update children: 'cashchange'
            this.SetChildren <CashChange> (info['cashchange'], 'cashchange', CashChange, 'place');
        }

        if ('audits' in info) {   // update children: 'audits'
            this.SetChildren <Audit> (info['audits'], 'audits', Audit, 'place');
        }

        if ('accounts' in info) {   // update children: 'audits'
            this.SetChildren <PayAccount> (info['accounts'], 'accounts', PayAccount, 'place');
        }
        
        if ('transaction' in info){  // update children 'payment'
            this.SetChildren <Payment> (info['transaction'], 'transaction', Payment, 'place');
        }

        if ('clients' in info){     // update children 'clients'
            this.SetChildren <Business> (info['clients'], 'clients', Business, 'place');
        }

        if ('service' in info){     // update children 'service'
            this.SetChildren <PlaceService> (info['service'], 'service', PlaceService, 'place');
        }

        if ('stripe' in info){  // update children: 'stripe'
            if (info['stripe'].length > 0){
                this.SetChild('stripe', new StripeAccount(info['stripe'][0], this.data, this._objoptions), 'stripe');
            }
            else {
                this.SetChild('stripe', null, 'stripe');
            }
        }   
        
        this._optionsmap = null;    // to be reloaded
    }
}

export class Place extends PlaceData {
    constructor(objid: string, data: dataService, objoptions: ObjectOptions = null){
        super('PLACE', objid, data, objoptions);
    }

    Copy(store: Array<DataObject> = []) : Place {
        return this._Copy(store) as Place;
    }

    /****************************/
    /* EMBEDED TICKET LOGO      */
    /****************************/

    private _logob64 = null;
    private _logourl = null;

    get embeddedlogo(){
        return this._logob64;
    }

    protected _OnUpdate(info){
        super._OnUpdate(info);

        // obtain the print logo in base64
        if (this._logourl != this.logo){
            GenericUtils.imageTobase64(this.logo, 'image/jpeg', 0.8).then(
            data => {
                this._logob64 = data;
            });

            this._logourl = this.logo;
        }

        // apply the forced mode when required
        let _forcedmode = this.forcedMode;
        if (_forcedmode){
            setTimeout(() => {  // allow place to commit
                this.data.ViewMode = _forcedmode;
            }, 0);
        }
    }

    /****************************/
    /* REFRESH BY SOURCE        */
    /****************************/

    private _TargetEvent = {
        'PLACE': new Subject<any>(),
        'QRCODE': new Subject<any>(),
        'PRODUCT': new Subject<any>(),
        'TICKET': new Subject<any>(),
        'TILL': new Subject<any>(),
        'EXTRA': new Subject<any>(),
        'OFFER': new Subject<any>(),
        'STAFF': new Subject<any>(),
        'CLIENT': new Subject<any>(),
        'FLOOR': new Subject<any>(),
        'SESSION': new Subject<any>(),
        'USER': new Subject<any>(),
    };

    OnTargetRefresh(target){
        if (target in this._TargetEvent){
            return this._TargetEvent[target].asObservable();
        }

        console.warn("[LOST REFRESH] Unrecognized updated target: '" + target + "'")
    }

    private _table2update = {
        'EVENT': null,      // do not update events
        
        'PLACE': [ 'PLACE' ],
        'ADDRESS': [ 'PLACE' ],
        'STRIPE': [ 'PLACE' ],
        'PLACELINK': [ 'PLACE' ],
        'PLACEOPT': [ 'PLACE' ],
        'DISCOUNT': [ 'PLACE' ],
        'RASPPI': [ 'PLACE' ],
        'PRINTER': [ 'PLACE' ],
        'PRINTERPRODUCT': [ 'PLACE' ],
        'PLACEAREA': [ 'FLOOR' ],
        'DRAWITEM': [ 'FLOOR' ],
        'CASHCHANGE': [ 'TILL' ],
        'AUDIT': [ 'TILL' ],
        'TILL': [ 'TILL '], 
        'AUDITINFO': [ 'TILL' ],
        'TICKET': [ 'TICKET' ],
        'TICKETCHANGE': [ 'TICKET' ],
        'TICKETBAI': [ 'TICKET' ],
        'TICKETSII': [ 'TICKET' ],
        'TICKETINVOICE': [ 'TICKET' ],
        'TICKETEXTRA': [ 'TICKET' ],
        'TICKETOFFER': [ 'TICKET' ],
        'TICKETDISCOUNT': [ 'TICKET' ],
        'TICKETOPTION': [ 'TICKET' ],
        'TICKETPRODUCT': [ 'TICKET' ],
        'PAYMENT': [ 'TICKET', 'PLACE' ],
        'EXTRA': [ 'EXTRA' ],
        'EXTRAPRODUCT': [ 'EXTRA' ],
        'EXTRATABLE': [ 'EXTRA' ],
        'EXTRAPERIOD': [ 'EXTRA' ],
        'OFFER': [ 'OFFER' ],
        'OFFERPRODUCT': [ 'OFFER' ],
        'OFFERPERIOD': [ 'OFFER' ],
        'DISCOUNTPERIOD': [ 'OFFER' ],
        'ASKWAITER': [ 'TICKET', 'QRCODE' ],
        'INVOICEBUSINESS': [ 'CLIENT' ],
        'PAYACCOUNT': [ 'CLIENT' ],
        'ACCOUNTINVOICE': [ 'CLIENT' ],
        'PRODUCT': [ 'PRODUCT' ],
        'FAMILY': [ 'PRODUCT' ],
        'FAMILYPERIOD': [ 'PRODUCT' ],
        'CATEGORY': [ 'PRODUCT' ],
        'CATEGORYDEP': [ 'PRODUCT' ],
        'PRODUCTOPT': [ 'PRODUCT' ],
        'PRESELECT': [ 'PRODUCT' ],
        'PRESELECTOPT': [ 'PRODUCT' ],
        'QRCODE': [ 'QRCODE' ],
        'SESSION': [ 'SESSION' ],
        'USER': [ 'USER' ],
        'USERADDRESS': [ 'USER' ],
        'STAFF': [ 'STAFF' ]
    };

    private _DoRefresh(targets){
        this._onRefresh.next();     // refresh event
        for (let _target of targets){
            if (_target == 'PLACE'){    // notify all
                for(let _event in this._TargetEvent){
                    this._TargetEvent[_event].next();
                }
            }
            else {  // notify specific change
                this._TargetEvent[_target].next();
            }
        }
    }

    private _tick_refreshtable = new Set <string> ();

    DoRefresh(table:string, now:boolean = false){
        let _targets = []; // get the targets out of the prodived target table
        if ((table in this._table2update) && this._table2update[table]) {
            _targets = _targets.concat(this._table2update[table]);
        }
        else {
            console.warn("[LOST REFRESH] Unrecognized updated table: '" + table + "'")
        }

        // send the update event based on the now parameter
        if (now && this.data.Clock.Enable){
            this._DoRefresh(_targets);
        }
        else {
            for(let _target of _targets){
                this._tick_refreshtable.add(_target); 
            }

            if (this._tick_subscription == null){
                this._tick_subscription = this.data.Clock.OnRefreshTick.subscribe(
                data => {
                    this._tick_subscription.unsubscribe();
                    this._tick_subscription = null;

                    this._DoRefresh([...this._tick_refreshtable]);
                    this._tick_refreshtable.clear();
                });
            }        
        }
    }

    /****************************/
    /* CART METHODS             */
    /****************************/

    private _onCart = new Subject <void> ();
    public OnCart = this._onCart.asObservable();

    AddCart(ticket: Ticket){
        this.AddChild('cart', ticket, 'place');     // add to cart
        if ((ticket.IsValid) && (!ticket.CopyOf)){
            this.DelTicket(ticket);                 // remove from place (ignore copies)
        }

        this._onCart.next();
    }

    DelCart(ticket: Ticket){
        this._onCart.next();
        
        this.DelChild('cart', ticket, 'place');     // remove from cart
        if ((ticket.IsValid) && (!ticket.CopyOf)){    
            this.AddTicket(ticket);                 // add to place (ignore copies)
        }
    }

    get cart() : Array <Ticket> {
        return this._chldlist['cart'] || [];
    }

    AddTicket(child: Ticket){
        super.AddTicket(child);
        if (child.qrcode){
            child.qrcode.AddTicket(child);
        }
        
        this.DoRefresh('TICKET', true);
    }

    DelTicket(child: Ticket){
        super.DelTicket(child);
        if (child.qrcode){
            child.qrcode.DelTicket(child);
        }

        this.DoRefresh('TICKET', true);
    }

    /****************************/
    /* CLASS MEMBERS            */
    /****************************/

    get status(): string {
        return super.status;
    }

    set status(value: string){
        if (this.status == 'DE'){
            return;     // cannot modify deleted items
        }

        super.status = value;

        if (this.user){
            if ((this.status == 'DE') && (this.ToInsert) && (!this.CopyOf || this.CopyOf.ToInsert)){
                this.user.DelPlace(this);
            }
            else {
                this.user.DoRefresh('PLACE');
            }    
        }
    }

    get families() : Array <Family> {
        let _families = [];
        for (let _product of this.products){
            if (_product.IsFamily){
                _families.push(_product.family);
            }
        }
        return _families;
    }
    
    get till(): PlaceService {
        // find a valid service for this device (if multiple POS of for the place)
        let _services = this._chldlist['service'] || [];
        for(let _service of _services){
            if (_service.IsValid && (!this.MultiplePOS || _service.IsLocal)){
                return _service;
            }
        }    

        return null;
    }

    get possvc() : Array <PlaceService> {
        let _posservices = [];

        let _services = this._chldlist['service'] || [];
        for(let _service of _services){
            if (_service.IsValid){
                _posservices.push(_service);
            }
        }            

        return _posservices;
    } 

    /****************************/
    /* COMMIT OVERLOAD          */
    /****************************/

    private _onCommit = new Subject<any>();
    public OnCommit = this._onCommit.asObservable();

    async DoCommit(force: boolean = false){
        if (this._toInsert){
            this.user.AddPlace(this);
        }

        let _result = await super.DoCommit(force);
        this._onCommit.next();  // notify the place commit operation
        return _result;
    }

    /****************************/
    /* LICENSE EXTEND           */
    /****************************/

    private _onExtend = new Subject<any>();
    public OnExtend = this._onExtend.asObservable();

    DoExtend(info){
        this._onExtend.next({
            expires: new Date(Date.parse(this.mysqlToDateStr(info['expires']))),
            price: info['price'],
            currency: info['currency']
        })
    }

    /****************************/
    /* BUILD FROM DATABASE      */
    /****************************/

    protected _FromData(data){
        let _options = data['options'];
            
        delete data['options'];
        this._OnUpdate(data);

        // add the address
        if (data['address']){
            let _objid = data['address']['objid'];
            let _address = new Address(_objid, this.data, { volatile: true });
            if (_address){
                _address.FromData(data['address']);
            }
            this.address = _address;
        }

        // add the options into the place
        for(let _data of _options){
            let _objid = _data['objid'];
            let _option = new PlaceOption(_objid, this.data, { volatile: true });
            if (_option){
                _option.FromData(_data);
            }

            this.SetConfigOption(_option.opkey, _option.value);
        }
    }

    /****************************/
    /* CUSTOM METHODS           */
    /****************************/

    async Preload(){
        // preload the place certificates in the session storage
        let _certurl = this.PemCert?.url;
        if (_certurl){
            let _itemid = this.objid + "_secured_cr";
            let _usercert = await this.data.FetchFile(_certurl);
            if (_usercert){
                let _storeddata = GenericUtils.arrayBufferToBase64(_usercert);
                if (_storeddata){
                    await this.data.SetSessionSecured(_itemid, _storeddata);
                }
            }
        }

        // preload the place cert password in the session storage
        let _certpwd = await this.data.GetServerSecured('PLACE', this.objid, 'certkey');
        if (_certpwd){
            let _itemid = this.objid + "_secured_cp";
            let _storeddata = _certpwd;
            if (_storeddata){
                await this.data.SetSessionSecured(_itemid, _storeddata);
            }
        }

        console.info("[PLACE] is now loaded!")
    }

    get IsCommited(){
        return (this.ToInsert == false) && (this.objid != null);
    }

    get IsActive(){
        return (this._place.status != 'DE');
    }

    get nextTableNo(){
        let _next = 1;
        for(let _table of this.tables){
            if ((_table.status == 'AC') || (_table.status == 'PA')){
                _next++;
            }
        }
        return _next;   // this is orientative and will be replaced in the server
    }
    
    get PlaceOptions(): Array <PlaceOption> {
        return [...this.OptionsMap.values()];     // removes duplicates
    }

    GetConfigList(key){
        let _option = this.OptionsMap.get(key)
        if (_option){
            return (_option.value);
        }
        return null;
    }

    GetConfigOption(key, defval = false){
        let _option = this.OptionsMap.get(key)
        if (_option){
            return (_option.value) && (_option.value != "0");
        }
        return defval;
    }

    SetConfigOption(opkey, opval){
        if (typeof opval == "boolean"){
            opval =  (opval) ? '1': '0';
        }

        if (!this.IsLoaded  && !this.ToInsert){
            console.error("ERROR: Place is not ready for operation!");
        }
        else {
            let _option = this.OptionsMap.get(opkey)
            if (_option){
                _option.value = opval;
            }
            else {
                console.info("[PLACEOPT] Adding new option '" + opkey + "' to place [" + this.objid + "]");

                _option = new PlaceOption(null, this.data);
                if (_option){
                    _option.place = this;
                    _option.opkey = opkey;
                    _option.value = opval;
                }
    
                this.AddOption(_option);
            }

            this._optionsmap = null;
        }

        this.DoRefresh('PLACE');
    }

    private _IsDeviceInOption(option){
        let _option = this.GetConfigList(option);
        if (_option){
            let _devices = _option.split('|');
            for(let _device of _devices){
                if (this.data.device == _device){
                    return true;
                }
            }    
        }
        return false;
    }

    get MultiplePOS(){
        return this.GetConfigOption('optionMultiplePOS');
    }

    get IsPOSDevice(){
        return this._IsDeviceInOption('optionPosTerminal');
    }

    get isKiosk(){
        let _option = this.GetConfigList('optionVirtualKeyboard');
        if (_option){
            let _devices = _option.split('|');
            for(let _device of _devices){
                if (this.data.device == _device){
                    return true;
                }
            }    
        }
        return false;
    }

    get forcedMode(){
        let _option = this.GetConfigList('optionForceDevice');
        if (_option){
            let _devices = _option.split('|');
            for(let _device of _devices){
                let _parts = _device.split(';')
                if (_parts[0] == this.data.device){
                    return (_parts.length > 1) ? _parts[1] : null;
                }
            }    
        }
        return null;
    }

    get TaxRate(){
        let _option = this.OptionsMap.get('optionTaxRate');
        if (_option){
            return (Number(_option.value));
        }
        return AppConstants.defaultTaxRate;        
    }

    get Language(){
        let _language = 'ES';   // default language

        /*
        switch (this.address?.country){
            // only ES is supported
        } 
        */  

        return _language;
    }

    get AcceptQR(){
        return this.GetConfigOption('optionQrOrders');
    }

    get IsPrepay(){
        return this.GetConfigOption('optionPrePayment');
    }

    get IsDirect(){
        return !this.GetConfigOption('optionTrackTicket');
    }

    get TableService(){
        return this.GetConfigOption('optionTableService');
    }

    get ExtendTicket(){
        return this.GetConfigOption('optionExtendTicket');
    }

    get ExtendOrder(){
        return this.GetConfigOption('optionExtendChange');
    }

    get CashModal(){
        return this.GetConfigOption('optionCashModal');
    }

    get OpenOnCash(){
        return this.GetConfigOption('optionOpenCashpay');
    }

    get OpenOnCard(){
        return this.GetConfigOption('optionOpenCardpay');
    }

    get AcceptCash(){
        return true;
    }

    get AcceptCard(){
        return this.GetConfigOption('optionCreditCard');
    }

    get AcceptOnline(){
        return this.GetConfigOption('optionOnlinePay') && (this.stripe && this.stripe.IsActive);
    }

    get AcceptAccount(){
        let _accept = this.GetConfigOption('optionPayAccount') && (this.payaccounts.length > 0);
        if (_accept){
            _accept = this.payaccounts.some(
            _account => {
                return _account.IsValid && !_account.IsBlocked;
            });
        }
        
        return _accept;
    }

    get ProductCodes(){
        return this.GetConfigOption('optionProductCodes');
    }

    get CardMinimum(){
        let _option = this.OptionsMap.get('amountCreditCard');
        if (_option){
            return (Number(_option.value));
        }
        return 0;
    }

    get OnlineMinimum(){
        let _option = this.OptionsMap.get('amountOnlinePay');
        if (_option){
            return (Number(_option.value));
        }
        return 0;
    }

    get TicketbaiAvailable(){
        if (!this.fiscal || !this.fiscal.formatted){
            return false;
        }

        let _provinces = [];

        _provinces = _provinces.concat([ /Bizkaia/i, /Vizcaya/i ]);
        _provinces = _provinces.concat([ /Guipúzcoa/i, /Guipuzcoa/i, /Gipuzkoa/i ]);
        _provinces = _provinces.concat([ /Álava/i, /Alava/i, /Araba/i ]);

        let _address = this.fiscal.formatted;
        for(let _pattern of _provinces){
            if (_address.search(_pattern) != -1){
                return true;
            }
        }

        return false;
    }

    private _safedisable_bai = false;   // set to true to disable TICKETBAI operations
    get SendBAIEnabled(){
        return !this._safedisable_bai && this.TicketbaiAvailable && this.GetConfigOption('optionBaiEnabled');
    }    

    private _safedisable_sii = true;   // set to true to disable SII operations
    get SendSIIEnabled(){
        return !this._safedisable_sii && this.GetConfigOption('optionSiiEnabled');
    }

    get FacturaeEnabled(){
        return this.GetConfigOption('optionFacturaE');
    }

    get PemCert() : PlaceLink {
        for(let _link of this.links){
            if (_link.link == 'pemcert'){
                return (_link.url || _link.base64) ? _link : null;
            }
        }
        return null;
    }

    get PrintLogo(){
        return this.GetConfigOption('optionPrintLogo');
    }

    get IsBarOpen(){
        return this.AcceptQR && this.GetConfigOption('barIsOpen');
    }

    set IsBarOpen(value: boolean){
        this.SetConfigOption('barIsOpen', value ? '1': '0');
    }

    get IsKitOpen(){
        return this.AcceptQR && this.GetConfigOption('kitIsOpen');
    }

    set IsKitOpen(value: boolean){
        this.SetConfigOption('kitIsOpen', value ? '1': '0');
    }

    IsOwner(user){
        for(let _waiter of this.waiters){
            if (_waiter.IsValid && (_waiter.user == user)){
                return false;   // it is a waiter
            }
        }
        return true;
    }

    IsWaiter(user){
        for(let _waiter of this.waiters){
            if (_waiter.user == user){
                return true;   // it is a waiter
            }
        }
        return false;
    }

    GetPlaceLink(link){
        for(let _link of this.links){
            if (_link.link == link){
                return (_link.url);
            }
        }
        return '';
    }

    get PdfMenu() : PlaceLink {
        for(let _link of this.links){
            if (_link.link == 'pdfmenu'){
                return (_link.url || _link.base64) ? _link : null;
            }
        }
        return null;
    }

    get IsExpired(){
        if ((this._place) && (this._place.expires)){
            return (this._place.expires.getTime() < new Date().getTime()); 
        }
        return true;
    }

    SetLink(lkkey, lkurl, lkb64){
        let _exists = false;
        for(let _link of this.links){
            if (_link.link == lkkey){
                _link.url = lkurl;
                _link.base64 = lkb64;
                _exists = true;
            }
        }

        if (!_exists){
            let _link = new PlaceLink(null, this.data);
            if (_link){
                _link.place = this;
                _link.link = lkkey;
                _link.url = lkurl;
                _link.base64 = lkb64;
            }

            this.AddLink(_link);
        }
    }

    CreateTicket(table: QrCode): Ticket {
        let _ticket: Ticket = null;

        // recover existing ticket
        for(let _cartticket of this.cart){
            if ((_cartticket.qrcode == table) && (_cartticket.status == 'PR') && (_cartticket.IsRecent)){
                _ticket = _cartticket;
            }
        }    

        // create a new ticket (if not recovered)
        if (!_ticket){
            _ticket = new Ticket(null, this.data);
            if (_ticket){
                _ticket.session = this.data.session;
                _ticket.place = this;
                _ticket.qrcode = table;
                _ticket.status = 'PR';

                this.AddCart(_ticket);
            }    
        }

        return _ticket;
    }

    ProductSearch(search){
        let _search = new searchTool();
        if (_search){
            let _products = [];

            for (let _product of this.products){
                if (_product.IsValid && !_product.IsVarious && !_product.IsGroup){
                    _products.push(_product);
                }
            }

            return _search.doSearch(search, _products, 80);
        }
        return [];
    }

    /* User permissions check */

    AllowStaffManage(_user){
        for(let _waiter of this.waiters){
            if (_waiter.IsValid && (_waiter.user == _user)){
                return _waiter.AllowStaffManage;
            }
        }

        return true;    // it is the owner
    }        

    AllowOfferManage(_user){
        for(let _waiter of this.waiters){
            if (_waiter.IsValid && (_waiter.user == _user)){
                return _waiter.AllowOfferManage;
            }
        }

        return true;    // it is the owner
    }

    AllowProductManage(_user){
        for(let _waiter of this.waiters){
            if (_waiter.IsValid && (_waiter.user == _user)){
                return _waiter.AllowProductManage;
            }
        }

        return true;    // it is the owner
    }

    AllowTableManage(_user){
        for(let _waiter of this.waiters){
            if (_waiter.IsValid && (_waiter.user == _user)){
                return _waiter.AllowTableManage;
            }
        }

        return true;    // it is the owner
    }

    AllowExtraManage(_user){
        for(let _waiter of this.waiters){
            if (_waiter.IsValid && (_waiter.user == _user)){
                return _waiter.AllowTableManage && _waiter.AllowExtraManage;
            }
        }

        return true;    // it is the owner
    }

    AllowPlaceManage(_user){
        for(let _waiter of this.waiters){
            if (_waiter.IsValid && (_waiter.user == _user)){
                return _waiter.AllowPlaceManage;
            }
        }

        return true;    // it is the owner
    }

    AllowLicenceManage(_user){
        for(let _waiter of this.waiters){
            if (_waiter.IsValid && (_waiter.user == _user)){
                return _waiter.AllowLicenceManage;
            }
        }

        return true;    // it is the owner
    }

    AllowCompliment(_user){
        for(let _waiter of this.waiters){
            if (_waiter.IsValid && (_waiter.user == _user)){
                return _waiter.AllowCompliment;
            }
        }

        return true;    // it is the owner
    }

    AllowAvailability(_user){
        for(let _waiter of this.waiters){
            if (_waiter.IsValid && (_waiter.user == _user)){
                return _waiter.AllowAvailability;
            }
        }

        return true;    // it is the owner
    }

    AllowPOSManage(_user){
        for(let _waiter of this.waiters){
            if (_waiter.IsValid && (_waiter.user == _user)){
                return _waiter.AllowPOSManage;
            }
        }

        return true;    // it is the owner
    }

    AllowReports(_user){
        for(let _waiter of this.waiters){
            if (_waiter.IsValid && (_waiter.user == _user)){
                return _waiter.AllowReports;
            }
        }

        return true;    // it is the owner
    }

    AllowTillManage(_user){
        for(let _waiter of this.waiters){
            if (_waiter.IsValid && (_waiter.user == _user)){
                return _waiter.AllowTillManage;
            }
        }

        return true;    // it is the owner
    }

    AllowTillOpen(_user){
        for(let _waiter of this.waiters){
            if (_waiter.IsValid && (_waiter.user == _user)){
                return _waiter.AllowTillManage || _waiter.AllowTillOpen;
            }
        }

        return true;    // it is the owner
    }

    AllowTicketReopen(_user){
        for(let _waiter of this.waiters){
            if (_waiter.IsValid && (_waiter.user == _user)){
                return _waiter.AllowTicketReopen;
            }
        }

        return true;    // it is the owner
    }

    AllowTicketCancel(_user){
        for(let _waiter of this.waiters){
            if (_waiter.IsValid && (_waiter.user == _user)){
                return _waiter.AllowTicketCancel;
            }
        }

        return true;    // it is the owner
    }

    AllowTicketUpdate(_user){
        for(let _waiter of this.waiters){
            if (_waiter.IsValid && (_waiter.user == _user)){
                return _waiter.AllowTicketUpdate;
            }
        }

        return true;    // it is the owner
    }

    AllowTicketReturn(_user){
        for(let _waiter of this.waiters){
            if (_waiter.IsValid && (_waiter.user == _user)){
                return _waiter.AllowTicketReturn;
            }
        }

        return true;    // it is the owner
    }
}
