import { dataService } from '@app/modules/data';
import { DataObject, ObjectOptions } from './base';

import { Ticket } from './ticket';

export interface _TicketMixed {
    ticket: number,
    payment: string,
    amount: number
};

interface _TicketMixedData extends _TicketMixed {
    objid?: number;
    _uuid?: string;
    created?: Date;
};

abstract class TicketMixedData extends DataObject {
    protected _mixed: _TicketMixedData = {
        ticket: null,
        payment: null,
        amount: null
    };

    constructor(table: string, objid: string, data: dataService, objoptions: ObjectOptions){
        super(table, objid, data, objoptions);
        this._mixed.created = new Date();
    }

    /****************************/
    /* CLASS MEMBERS            */
    /****************************/

    get created(){
        return this._mixed.created;
    }

    get ticket(): Ticket {
        return this._children['ticket'] || null;
    }

    set ticket(value: Ticket){
        if (this.SetChild('ticket', value, 'ticket')){
            this.ToUpdate = true;
        }
    }

    get payment(): string {
        return this._mixed.payment;
    }

    set payment(value: string){
        if (this.patchValue(this._mixed, 'payment', value)){
            this.ToUpdate = true;
        }
    }

    get amount(): number {
        return this._mixed.amount;
    }

    set amount(value: number){
        if (this.patchValue(this._mixed, 'amount', value)){
            this.ToUpdate = true;
        }
    }

    /****************************/
    /* COMMIT OPERATION         */
    /****************************/

    protected get Change() {
        return {
            ticket: this._mixed.ticket,
            payment: this._mixed.payment,
            amount: this._mixed.amount
        };
    }
    
    protected get Depend() {
        return {
            ticket: { item: this.ticket, relation_info: { to: 'mixed', by: 'ticket' } },   // this[by -> 'ticket'][to -> 'mixed'] => this
        };
    }

    protected get Children(){
        return [ /* empty */ ];
    }

    /****************************/
    /* DATA OBJECT              */
    /****************************/
    
    private _patchData(_mixed: _TicketMixed){
        let _toUpdate = false;

        _toUpdate = this.patchValue(this._mixed, 'ticket', _mixed['ticket']) || _toUpdate;
        _toUpdate = this.patchValue(this._mixed, 'payment', _mixed['payment']) || _toUpdate;
        _toUpdate = this.patchValue(this._mixed, 'amount', _mixed['amount']) || _toUpdate;

        return _toUpdate;
    }   

    set Data(_mixed: _TicketMixed){
        this.patchValue(this._mixed, 'created', _mixed['created']);
        
        if (this._patchData(_mixed)){
            this.ToUpdate = true;
        }
    }

    get Info(){
        return this._mixed;
    }

    set Info(value){
        this.DoPatchValues(value);
    }

    private DoPatchValues(_mixed: _TicketMixed){
        this._patchData(_mixed);

        if (_mixed['ticket']){    // update children: 'ticket'
            let _objid = _mixed['ticket'].toString();
            this.SetChild('ticket', new Ticket(_objid, this.data, this._objoptions), 'ticket');
        }
        else {
            this.SetChild('ticket', null, 'ticket');
        }
    }

    private _ddbb(info): _TicketMixedData {
        let _mixed: _TicketMixedData = {
            objid: info['objid'] ? parseInt(info['objid']) : null,
            created: new Date(Date.parse(this.mysqlToDateStr(info['created']))),
            ticket: info['ticket'] ? parseInt(info['ticket']) : null,
            payment: info['payment'],
            amount: info['amount'] ? parseFloat(info['amount']) : null,
        };
        return _mixed;
    }

    protected _OnUpdate(info){
        let _offer = this._ddbb(info);
        this.patchValue(this._mixed, 'objid', _offer['objid']);
        this.patchValue(this._mixed, 'created', _offer['created']);
        this.DoPatchValues(_offer);
    }
}

export class TicketMixedPayment extends TicketMixedData {
    constructor(objid: string, data: dataService, objoptions: ObjectOptions = null){
        super('TICKETMIXEDPAY', objid, data, objoptions);
    }

    Copy(store: Array<DataObject> = []): TicketMixedPayment {
        return this._Copy(store) as TicketMixedPayment;
    }

    /****************************/
    /* CUSTOM METHODS           */
    /****************************/

    get IsValid() {
        if (!this.ticket){
            return false;
        }
        
        return (this.ticket.payment == 'PAYMIXED');
    }
}


